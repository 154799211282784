import React from 'react';
import styles from './TrainingForCDAP.module.scss';
import Typography from '@material-ui/core/Typography';
import {
  TRAINING_BLOCK_HEADING,
  TRAINING_ITEMS,
} from '../../constants/services-pages';
import { graphql, Link, useStaticQuery } from 'gatsby';
import HorizontalCard from '../HorizontalCard';
import styled from 'styled-components';

const TrainingForCDAP = ({ padding, name }) => {
  const { icons } = useStaticQuery(graphql`
    query {
      icons: allFile(
        filter: {
          sourceInstanceName: { eq: "trainingCDAP" }
          extension: { eq: "svg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);

  const TrainingBlock = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1320px;
  padding: ${props => props.padding};
  @media (max-width: 960px){
  padding: 64px 0
  }
  `

  return (
    <TrainingBlock className={styles['trainings-block']} padding={padding}>
      <Typography variant={'h2'} className={styles['trainings-block__heading']}>
        {TRAINING_BLOCK_HEADING + ' ' + name}
      </Typography>

      <div className={styles['trainings-block__cards-block']}>
        {TRAINING_ITEMS.map(({ icon, title }, index) => (
          <Link key={title} to={'/contact-us'}>
            <HorizontalCard
              hasBoxShadow={true}
              icon={icons.nodes.find(({ name }) => name === icon).publicURL}
              title={title}
              width={'100%'}
              height={'100%'}
              padding={`${
                index > 1 ? (index > 2 ? '24px' : '36px') : '53px'
              } 0 0 0`}
            />
          </Link>
        ))}
      </div>
    </TrainingBlock>
  );
};

export default TrainingForCDAP;
