import React, {useState} from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import {
  CONTACT_OUR_EXPERTS,
  FIRST_BLOCK_FIRST_PARAGRAPH,
  FIRST_BLOCK_FOURTH_PARAGRAPH,
  FIRST_BLOCK_HEADING,
  FIRST_BLOCK_SECOND_PARAGRAPH,
  FIRST_BLOCK_THIRD_PARAGRAPH,
  GET_STARTED,
  OFFER_BLOCK_HEADING,
  OFFER_CARDS,
  OFFER_LIST_ITEMS,
  OPTIONS_BLOCK_FIFTH_ITEM,
  OPTIONS_BLOCK_FIRST_ITEM,
  OPTIONS_BLOCK_FOURTH_ITEM,
  OPTIONS_BLOCK_SECOND_ITEM,
  OPTIONS_BLOCK_THIRD_ITEM,
  OPTIONS_LEFT_BLOCK_HEADING,
  OPTIONS_RIGHT_BLOCK_HEADING,
  SERVICE_BLOCK_HEADING,
  SERVICE_BLOCK_SUBHEADING,
  SERVICE_CDAP_HEADING,
} from '../../constants/services-pages';
import styles from './ServicesCDAP.module.scss';
import Typography from '@material-ui/core/Typography';
import HorizontalCard from '../HorizontalCard';
import TrainingForCDAP from '../TrainingForCDAP';
import CustomSoftwareDevelopment from "../CustomSoftwareDevelopment";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Button from "@material-ui/core/Button";

const ServicesCDAP = () => {
  const [expanded, setExpanded] = useState(false)
  const { bg, icons } = useStaticQuery(graphql`
    query {
      bg: allFile(
        filter: {
          sourceInstanceName: { eq: "servicesCDAP" }
          name: { eq: "bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      icons: allFile(
        filter: {
          sourceInstanceName: { eq: "servicesCDAP" }
          extension: { eq: "svg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <InnerPagesUpperBanner
        bg={bg.nodes[0].publicURL}
        text={SERVICE_CDAP_HEADING}
      />

      <div className={styles['first-block']}>
        <Typography variant={'h2'} className={styles['first-block__heading']} align="center" color="secondary">
          {FIRST_BLOCK_HEADING}
        </Typography>

        <div className={`${styles['first-block__text']} ${expanded ? styles['expanded'] : ''}`}>
          <div>
            <Typography className={styles['first-block__text__first-p']}>
              {FIRST_BLOCK_FIRST_PARAGRAPH}
            </Typography>
            <Typography>{FIRST_BLOCK_SECOND_PARAGRAPH}</Typography>
          </div>

          <div>
            <Typography>{FIRST_BLOCK_THIRD_PARAGRAPH}</Typography>
            <Typography>{FIRST_BLOCK_FOURTH_PARAGRAPH}</Typography>
          </div>

          <Button endIcon={expanded ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                  onClick={() => {
                    setExpanded(!expanded)
                  }}>
            Show {expanded ? 'less' : 'more'}
          </Button>
        </div>
        <div className={styles['first-block__options-block']}>
        <div className={styles['first-block__options-block__left-block']}>
          <Typography variant={'h3'}>{OPTIONS_LEFT_BLOCK_HEADING}</Typography>

          <div className={styles['first-block__options-block__content']}>
            <div className={styles['first-block__options-block__item']}>
              <hr
                style={{
                  background:
                    'linear-gradient(181.31deg, #4461F9 22.96%, #C597FF 93.92%)',
                }}
              />
              <Typography>{OPTIONS_BLOCK_FIRST_ITEM}</Typography>
            </div>

            <div className={styles['first-block__options-block__item']}>
              <hr
                style={{
                  background:
                    'linear-gradient(359.67deg, #3DB8FD 17.19%, #3B59F5 100%)',
                }}
              />
              <Typography>{OPTIONS_BLOCK_SECOND_ITEM}</Typography>
            </div>

            <div className={styles['first-block__options-block__item']}>
              <hr
                style={{
                  background:
                    'linear-gradient(90deg, #0093E6 1.57%, #41E8CA 98.82%)',
                }}
              />
              <Typography>{OPTIONS_BLOCK_THIRD_ITEM}</Typography>
            </div>
          </div>

          <Link to={'/contact-us'} id={'cdap_get-started-button'}>
            {GET_STARTED}
          </Link>
        </div>
        <div className={styles['first-block__options-block__right-block']}>
          <Typography variant={'h3'}>
            {OPTIONS_RIGHT_BLOCK_HEADING}
          </Typography>

          <div className={styles['first-block__options-block__content']}>
            <div className={styles['first-block__options-block__item']}>
              <hr
                style={{
                  background:
                    'linear-gradient(183.25deg, #D3ED30 -6.73%, #B3EF67 50.31%, #55E652 107.34%)',
                }}
              />
              <Typography>{OPTIONS_BLOCK_FOURTH_ITEM}</Typography>
            </div>

            <div className={styles['first-block__options-block__item']}>
              <hr
                style={{
                  background:
                    'linear-gradient(358.94deg, #FF9E2C -10.39%, #FDE400 85.06%)',
                }}
              />
              <Typography>{OPTIONS_BLOCK_FIFTH_ITEM}</Typography>
            </div>
          </div>

          <Link to={'/contact-us'} id={'cdap_experts-button'} className={styles['first-block__options-block__link']}>
            {CONTACT_OUR_EXPERTS}
          </Link>
          <Link to={'/contact-us'} id={'cdap_experts-button'} className={styles['first-block__options-block__link-mobile']}>
            {GET_STARTED}
          </Link>
        </div>
        </div>
      </div>

      <div className={styles['service-block']}>
        <Typography variant={'h2'} className={styles['service-block__heading']} align="center" color="secondary">
          {SERVICE_BLOCK_HEADING}
        </Typography>
        <Typography
          variant={'h4'}
          className={styles['service-block__subheading']}
        >
          {SERVICE_BLOCK_SUBHEADING}
        </Typography>

        <div className={styles['service-block__offer-block']}>
          <Typography variant={'h3'}>
            {OFFER_BLOCK_HEADING.map(line => (
              <React.Fragment key={line}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>

          <div className={styles['service-block__offer-block__divider']} />

          <div className={styles['service-block__offer-block__cards-block']}>
            {OFFER_CARDS.map(({ icon, title }) => (
              <HorizontalCard
                icon={icons.nodes.find(({ name }) => name === icon).publicURL}
                title={title}
                imgWidth={72}
                width={'40%'}
                key={title}
                padding={0}
              />
            ))}
          </div>

          <div className={styles['service-block__offer-block__divider']} />

          <ul>
            {OFFER_LIST_ITEMS.map(item => (
              <li key={item}>
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <TrainingForCDAP padding={'120px 0 150px'} name={'CDAP'} />
      <CustomSoftwareDevelopment />
    </div>
  );
};

export default ServicesCDAP;
