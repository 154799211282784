import React from 'react';
import Layout from '../components/Layout';
import { useSiteMetadata } from '../hooks';
import ServicesCDAP from '../components/ServicesCDAP';
import {graphql, useStaticQuery} from "gatsby";

const ServicesCDAPTemplate = ({ location }) => {
  const { subtitle: siteSubtitle } = useSiteMetadata();
    const { bg } = useStaticQuery(graphql`
        query {
            bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "cdap" } }) {
                nodes {
                    name
                    publicURL
                }
            }
        }
    `);
  return (
    <Layout title="CDAP"
            description={siteSubtitle}
            location={location}
            socialImage={bg.nodes[0].publicURL}>
      <ServicesCDAP />
    </Layout>
  );
};

export default ServicesCDAPTemplate;
